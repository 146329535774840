<template>
    <modal class="custodianModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">{{title}}</div>
        <div class="modal-body">
            <div class="search-box">
                <div class="search"><input type="text" placeholder="请输入编码、姓名检索" v-model="searchText" ></div>
                <div class="search-bnt">搜索</div>
            </div>
            <div class="panel panel-table" >
                <div class="panel-heading">
                    <div class="tr">
                        <div class="td selectChecked">选择</div>
                        <div class="td code">编码</div>
                        <div class="td name">名称</div>
                    </div>
                </div>
                <div class="panel-body" ref="box" >
                    <div class="tr" v-for="item in posUsersPage" :key="item" :class="{selected:item.User_ID==seleUser?.User_ID}" @click="posUsersClick(item)" @dblclick="dblCustodianClick(item)" > 
                        <div class="td selectChecked">
                            <span class="iconfont " :class="item.User_ID==seleUser?.User_ID?'icon-iconoption1':'icon-iconoption'"></span>
                        </div>
                        <div class="td code">{{item.User_Code}}</div>
                        <div class="td name">{{item.User_Name}}</div>
                    </div>
                </div>
            </div>
            <div class="item-operation">
                &nbsp; &nbsp; {{pageIndex}}/{{pageCount}} 页 &nbsp; {{posUsersFilter?.length}}条
                <div class="float-right">
                    <page-turning class="bnt-fangxiang" v-model="pageIndex" :pageCount="pageCount" type="up"><i class="iconfont icon-fangxiang-up"></i></page-turning>
                    <page-turning class="bnt-fangxiang" v-model="pageIndex" :pageCount="pageCount"><i class="iconfont icon-fangxiang-down"></i></page-turning>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="custodianconfirm()">确认</button>
        </div>
    </modal>
</template>

<script>


/**选择开卡人  */
export default {
    name:"custodianModel",
    emits:["closeModel","custodianconfirm"],
    props:{ 
        isShow:Boolean,
        /**标题 */
        title:{
            type:String,
            default:"选择开卡人"
        }
    },
    data(){
        return{
            seleUser:null,
            /**搜索 */
            searchText:'',
            pageIndex:1,
            pageSize:10,
            //全部用户信息
            posUsers:[],
            //销售员
            posSellers:[]
        }
    },
    mounted(){
        this.$nextTick(()=>{
            const loading = this.$loading({
                text: "加载数据中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            //获取 销售员 和 全部用户信息
            this.$cacheData.cacheBaseAll(['PosUsers','PosSellers']).then(all=>{
                loading.close();
                this.posUsers = all?.PosUsers?.data;
                this.posSellers = all?.PosSellers?.data;
            }).catch((e)=>{
                loading.close();
                console.log('数据获取失败：',e);
                this.$alert("数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
            })
            this.init();
        });
    },
    computed:{
        /**将 销售员 和 全部用户信息 合并 */
        posUserAll(){
            let data=[];
            this.posUsers?.forEach(item=>{
                if(!data.some(it=>it.User_ID==item.User_ID)){
                    data.push(item);
                }
            })
            this.posSellers?.forEach(item=>{
                if(!data.some(it=>it.User_ID==item.User_ID)){
                    data.push(item);
                }
            })
            return data;
        },
        /**筛选 */
        posUsersFilter(){
            let data=this.posUserAll||[];
            let MenuTxt=this.searchText.toLowerCase();
            if(MenuTxt!=''){
                data=data?.filter(item=>(item.User_Code||"").toLowerCase().indexOf(MenuTxt)>=0 || (item.User_Name||"").toLowerCase().indexOf(MenuTxt)>=0)
            }
            return data;
        },
        /**分页数据 */
        posUsersPage(){
            return this.posUsersFilter.slice((this.pageIndex-1)*this.pageSize,this.pageIndex*this.pageSize);
        },
        /**总页数 */
        pageCount(){
            return Math.ceil(this.posUsersFilter.length/this.pageSize)||1;
        }
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        },
        searchText(){
            this.seleUser=null;
            this.pageIndex=1;
        }
    },
    methods:{
        /**初始化数据 */
        init(){
            this.seleUser=null;
            this.pageIndex=1;
        },
        /**选择开卡人 */
        posUsersClick(item){
            this.seleUser = item
        },
        /**双击 */
        dblCustodianClick(item){
            this.seleUser = item
            this.custodianconfirm()
        },
        hide(){
            this.$emit("closeModel");
        },
        custodianconfirm(){
            this.$emit("custodianconfirm",this.seleUser);
        }
    }
}
</script>

<style lang="scss">
@import "./custodianModel.scss";
</style>